import * as React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

import { Layout } from '../components/Layout'
import { VideoBlogPosts } from '../components/VideoBlogPosts'

export const query = graphql`
  query MyQuery($limit: Int!, $skip: Int!) {
    prismicVideoblog {
      _previewable
      data {
        seo_title
        seo_description
        seo_keywords
        title_section_image {
          url
        }
        title {
          text
        }
        desc {
          richText
        }
      }
      url
      type
    }

    prismicTopMenu {
      ...TopMenuFragment
    }
    prismicFooter {
      ...FooterFragment
    }

    allPrismicVbItem(
      sort: { fields: data___date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        uid
        url
        data {
          title {
            text
          }
          date
          body {
            ... on PrismicVbItemDataBodyVideoblogInfo {
              id
              slice_type
              primary {
                description {
                  richText
                }
                video_url {
                  thumbnail_url
                }
              }
            }
          }
        }
      }
      pageInfo {
        currentPage
        pageCount
      }
    }
  }
`

const Videoblog = ({data, pageContext}) => {
  // const {data} = props
  if (!data) return null
  // console.log('videoblog data: ', data)
  console.log('context: ', pageContext)

  const videoblog = data.prismicVideoblog || {}
  const seo = {
    title: videoblog.data.seo_title,
    description: videoblog.data.seo_description,
    keywords: videoblog.data.seo_keywords,
  }
  console.log('seo: ', seo)

  const topMenu = data.prismicTopMenu || {}
  const footer = data.prismicFooter || {}

  const docs = data.allPrismicVbItem

  // const avatar = { backgroundImage: `url(${home.image.url})` }

  return (
    <Layout topMenu={topMenu.data} footer={footer} seo={seo}>
      <div className="bg-light" style={{backgroundImage:"url(" + videoblog.data.title_section_image.url + ")",backgroundPosition:"center",backgroundRepeat:"no-repeat",  backgroundSize:"cover"}}>
        <div className="py-lg-4" style={{backgroundColor:"#000000b4",backdropFilter:"blur(3px)"}}>
          <div className="container py-5">
              <h1 className="h3 text-white mb-0">{videoblog.data.title.text}</h1>
          </div>
        </div>
      </div>
      <div className="container py-5">
        <VideoBlogPosts docs={docs} context={pageContext} />
      </div>
    </Layout>
  )
}

export default withPrismicPreview(Videoblog)
